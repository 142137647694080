<template>
  <div class="myFocus">
    <ProjectList :redDot="'redDot'" :list="projectDataList" :marginRight="20" :windowWidth="900" />
    <Pagination :total="totalCount" :pageSize="16" @changeCurrentPage="changeCurrentPage"  v-if="projectDataList.length" />
  </div>
</template>

<script>
import ProjectList from '@/components/common/projectList'
import Pagination from '@/components/common/pagination'
import {followList} from '@/api/user/user'
export default {
  name: 'myFocus',
  components: {
    ProjectList,
    Pagination
  },
  data() {
    return {
      projectDataList: [],
      totalCount: 0,
      page: 1
    }
  },
  mounted() {
    this.project()
  },
  methods: {
    changeCurrentPage(value) {
      this.page = value
      this.project()
    },
    project() {
      const data = {
        psize: 16,
        page: this.page
      }
      followList(data).then(res => {
        this.totalCount = res.data.count
        this.projectDataList = res.data.list
        // console.log('打印', res.data.list)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .myFocus{
    padding: 24px;
    background: #fff;
    margin-top: 12px;
    min-height: 598px;
  }
</style>
